import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _26f4431e = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _1c5e0bd1 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _6739c5e2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _2154e400 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _dc38114e = () => interopDefault(import('../pages/profile/payment.vue' /* webpackChunkName: "pages/profile/payment" */))
const _0cc3ff06 = () => interopDefault(import('../pages/profile/plans.vue' /* webpackChunkName: "pages/profile/plans" */))
const _50c68a30 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _7cb98d24 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _538ce038 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _3249de5f = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _063dbccb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _38285e58 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _26f4431e,
    name: "404"
  }, {
    path: "/change-password",
    component: _1c5e0bd1,
    name: "change-password"
  }, {
    path: "/profile",
    component: _6739c5e2,
    name: "profile",
    children: [{
      path: "account",
      component: _2154e400,
      name: "profile-account"
    }, {
      path: "payment",
      component: _dc38114e,
      name: "profile-payment"
    }, {
      path: "plans",
      component: _0cc3ff06,
      name: "profile-plans"
    }]
  }, {
    path: "/reset-password",
    component: _50c68a30,
    name: "reset-password"
  }, {
    path: "/sign-in",
    component: _7cb98d24,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _538ce038,
    name: "sign-up"
  }, {
    path: "/verify-email",
    component: _3249de5f,
    name: "verify-email"
  }, {
    path: "/",
    component: _063dbccb,
    name: "index"
  }, {
    path: "/*",
    component: _38285e58,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
